<template>
  <div class="wrapper">
    <UnAuthHeader />
    <main class="page">
			<div class="container-615">
				<section class="entity" v-if="is_valid">
					<h1 class="entity__title title">Ваш email успешно подтвержден</h1>
					<div class="entity__img-success">
						<picture><source srcset="/img/success-nike.svg" type="image/webp"><img src="/img/success-nike.svg" alt="success-nike"></picture>
					</div>
				</section>
			</div>
		</main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../../helpers/api";

import UnAuthHeader from "../../components/headers/UnAuth.vue";
import Footer from "../../components/Footer.vue";

import { mapActions } from 'vuex';

export default {
  name: "RegisterConfirmation",
  props: ['token'],
  components: {
    UnAuthHeader,
    Footer,
  },
  async mounted() {
    try {
        const response = await API.send("validateEmailConfirmPage", {
          token: this.token
        }, "POST");

        if (response.status !== 0) {
          alert(response.user_message);
          return;
        }

        this.setUser(response.user);
        this.is_valid = true;
        setTimeout(() => {
          this.$router.push(`/profile`);
        }, 5000);
      } catch (e) {
        alert("Неизвестная ошибка. Попробуйте позже.");
      }
  },
  data() {
    return {
      is_valid: false
    }
  },
  methods: {
    ...mapActions(['setUser'])
  }
};
</script>
